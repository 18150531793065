import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionTitle from "../components/common/SectionTitle"
import PageImageHeader from "../components/common/PageImageHeader"
import SectionHeaderImage from "../images/schedulePageHeader.jpeg"
import styled from "styled-components"

const StyledBody = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`

const AdvertisePage = () => {
  return (
    <Layout>
      <PageImageHeader imageHeader={SectionHeaderImage} />
      <SectionTitle title="Advertise" />
      <StyledBody>
        <h1>COMMING SOON</h1>
      </StyledBody>
    </Layout>
  )
}

export default AdvertisePage
